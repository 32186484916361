<template>
	<Layout>
		<div>
			<SaveSpinner :loadFlag="ui.loadingflag"/>
			<div v-if="error">
				<div class="alert alert-danger">
					<Error />
				</div>
			</div>
			<div v-else-if="ui.loadingflag === false">
				<div class="content">
					<div>
						<form class="my-form" @submit.prevent="listProvisions">
							<div class="form-group">
								<FEC :n="$t('month')">
									<VSelect v-model="month" :q="renderSelectOptions()" :value="month" :v="{}"/>
								</FEC>
							</div>
							<div class="form-group">
								<FEC :n="$t('year')">
									<VNumber v-model="year" :value="year"></VNumber>
								</FEC>
							</div>
							<button type="submit" class="mx-auto btn btn-info d-block">{{$t('refresh')}}</button>
						</form>
					</div>
					<div v-if="pendingProvision">
						<div class="spinner-border mx-auto d-block" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</div>
					<div v-if="showProvision">
						<SaveSpinner :loadFlag="pendingProvision"/>
						<table class="mx-auto">
							<thead>
								<tr>
									<th>{{ $t("date") }}</th>
									<th>{{ $t("filename") }}</th>
									<th>{{ $t("download") }}</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="provision in provisions" :key="provision.fileid">
									<td>{{printDate(provision.createddate)}}</td>
									<td>{{provision.filename}}</td>
									<td class="text-right">
										<i v-if="pendingDownload[provision.fileid] == 0" class="fa fa-spinner fa-spin mr-1"></i>
										<i v-if="pendingDownload[provision.fileid] == 2" class="fa fa-exclamation-circle text-danger mr-1"></i>
										<label class="m-0">
											<i class="fa fa-download"></i>
											<button type="button" @click="getFileData(provision.fileid,provision.filename)" hidden></button>
										</label>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
import Layout from "../layouts/Layout.vue";
import SaveSpinner from "../views/Spinner.vue";
import Error from "../views/Error.vue";
import cloneDeep from "lodash.clonedeep";

import Vuex from "vuex";
//get an alias to mapState. I map the properties in the store to our computed aliases.
const ms = Vuex.mapState;
import VSelect from "../views/htmlelements/VSelect.vue";
import VNumber from "../views/htmlelements/VNumber.vue";
import FEC from "../views/htmlelements/FormElementContainer.vue";

import Page from "../pages/Page.vue";

//transactions must be declared in data even though we later set it in listTransactions or any validation won't bind to it! May not be necessar here though since we're not modifying it.
export default {
	data() {
		return {
			month: 1,
			year: new Date().getFullYear(),
			provisions: [],
			showProvision: false,
			pendingProvision: false,
			pendingDownload: {}
		};
	},
	computed: {
		...ms(["ui"])
	},
	components: { Error, Layout, SaveSpinner, VSelect, VNumber, FEC },
	mixins: [Page],
	methods: {
		renderSelectOptions: function(){
			let tmp = [];

			[1,2,3,4,5,6,7,8,9,10,11,12].forEach((value) => {
				tmp.push({
					"text": this.$t("month_"+value),
					value: value
				})
			})

			return tmp
		},
		listProvisions: async function() {
			this.pendingProvision = true
			this.showProvision = false;

			try {
				await this.$store.dispatch("listProvisions", {
					month: this.month,
					year: this.year
				});

				this.provisions = cloneDeep(this.$store.state.provisions);

				this.showProvision = true;
			} catch {
				this.showProvision = true;
			}

			this.pendingProvision = false

		},
		getFileData: async function (fileId, fileName){
			this.pendingDownload[fileId] = 0
			this.pendingDownload = {...this.pendingDownload}

			this.$store.dispatch("getProvisionFile", {fileId: fileId})
			.then((blob) => {

				this.downloadFile(blob, fileName);

				this.pendingDownload[fileId] = 1
				this.pendingDownload = {...this.pendingDownload}
			})
			.catch(() => {
				this.pendingDownload[fileId] = 2
				this.pendingDownload = {...this.pendingDownload}
			});

		},
		downloadFile: function(blob, name){
			let a = document.createElement("a");
			let url = URL.createObjectURL(blob);

			a.href = url;
			a.download = name;
			a.click();

			setTimeout(function() {
				window.URL.revokeObjectURL(url);
			}, 0);
		},
		printDate(dateString){
			if(dateString === null){
				return "";
			}
			return new Date(dateString).toLocaleString(undefined, {day: "2-digit", month: "2-digit", year: "numeric"});
		}
	},
	created() {

	}
}
</script>