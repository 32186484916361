<template>
	<Layout>
		<div>
			<SaveSpinner :loadFlag="ui.loadingflag" />
			<div v-if="error">
				<div class="alert alert-danger">
					<Error />
				</div>
			</div>
			<div v-else-if="loadingflag === false" class="content">
				<div class="row">
					<div class="legende mx-auto">
						<p><b>Legende:</b></p>
						<div>
							<div>Visits: {{$t('report_visits')}}</div>
							<div>BUA: {{$t('report_bua')}}</div>
							<div>BU: {{$t('report_bu')}}</div>
							<div>StBu: {{$t('report_stBu')}}</div>
							<div>BUA / Visits: {{$t('report_bua_visits')}}</div>
							<div>BU / Visits: {{$t('report_bu_visits')}}</div>
							<div>PAX: {{$t('report_pax')}}</div>
							<div>UmsatzGes: {{$t('report_umsatzges')}}</div>
							<div>UmsatzPAX: {{$t('report_umsatzpax')}}</div>
							<div> {{$t('report_change')}}: {{$t('report_change_legende')}}</div>
						</div>
					</div>
				</div>
				<div class="overflow-auto fixed-tableheight">
					<table class="text-right mx-auto">
						<thead class="font-weight-bold">
							<tr>
								<td>
									<button type="button" class="btn btn-outline-info" @click="exportReport">
										<i class="fa fa-file-excel-o"></i>
									</button>
								</td>
								<td colspan="11"></td>
							</tr>
							<tr>
								<th>{{$t('year')}}</th>
								<th>{{$t('month')}}</th>
								<th>Visits</th>
								<th>BUA</th>
								<th>BU</th>
								<th>StBu</th>
								<th class="white-space-nowrap">BUA / Visits</th>
								<th class="white-space-nowrap">BU / Visits</th>
								<th>PAX</th>
								<th>UmsatzGes</th>
								<th>UmsatzPAX</th>
								<th class="white-space-nowrap">{{$t('report_change')}}</th>
							</tr>
						</thead>
						<tbody v-for="(jahr, yearIndex) in partnerstatistics.jahre" :key="jahr.monat">
							<tr class="font-weight-bold jahresSumme">
								<td class="white-space-nowrap" colspan="2">Gesamt {{jahr.jahr}}</td>
								<td>{{jahr.visits.toLocaleString()}}</td>
								<td>{{jahr.bookingrequest_count.toLocaleString()}}</td>
								<td>{{jahr.booking_count.toLocaleString()}}</td>
								<td>{{jahr.stornos.toLocaleString()}}</td>
								<td>{{printDecimal(jahr.bak !== 0 && jahr.visits !== 0 ? jahr.bak / jahr.visits * 100 : 0)}} %</td>
								<td>{{printDecimal(jahr.booking_count !== 0 && jahr.visits !== 0 ? jahr.booking_count / jahr.visits * 100 : 0)}} %</td>
								<td>{{jahr.personen}}</td>
								<td>{{jahr.bookinggesamt_sum.toLocaleString(undefined,{style: 'currency', currency: 'EUR'})}}</td>
								<td>{{printDecimal(jahr.bookinggesamt_sum !== 0 && jahr.personen !== 0 ? jahr.bookinggesamt_sum / jahr.personen : 0)}} €</td>
								<td>{{calculateYearDiff(jahr.bookinggesamt_sum, yearIndex)}}%</td>
							</tr>
							<tr v-for="item in jahr.statistics" :key="item.monat">
								<td>{{jahr.jahr}}</td>
								<td>{{item.monat}}</td>
								<td>{{item.visits.toLocaleString()}}</td>
								<td>{{item.bookingrequest_count.toLocaleString()}}</td>
								<td>{{item.booking_count.toLocaleString()}}</td>
								<td>{{item.stornos.toLocaleString()}}</td>
								<td>{{printDecimal(item.bookingrequest_count !== 0 && item.visits !== 0 ? item.bookingrequest_count / item.visits * 100 : 0)}} %</td>
								<td>{{printDecimal(item.booking_count !== 0 && item.visits !== 0 ? item.booking_count / item.visits * 100 : 0)}} %</td>
								<td>{{item.personen}}</td>
								<td>{{item.bookinggesamt_sum.toLocaleString(undefined,{style: 'currency', currency: 'EUR'})}}</td>
								<td>{{printDecimal(item.bookinggesamt_sum !== 0 && item.personen !== 0 ? item.bookinggesamt_sum / item.personen : 0)}} €</td>
								<td>{{calculateMonthDiff(item.bookinggesamt_sum, yearIndex, item.monat)}}%</td>
							</tr>
							<tr>
								<td>&nbsp;</td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
						</tbody>
						<tfoot class="font-weight-bold">
							<tr>
								<td colspan="2">Total</td>
								<td>{{partnerstatistics.visits.toLocaleString()}}</td>
								<td>{{partnerstatistics.bookingrequest_count.toLocaleString()}}</td>
								<td>{{partnerstatistics.booking_count.toLocaleString()}}</td>
								<td>{{partnerstatistics.stornos.toLocaleString()}}</td>
								<td>{{printDecimal(partnerstatistics.bak !== 0 && partnerstatistics.visits !== 0 ? partnerstatistics.bak / partnerstatistics.visits * 100 : 0)}} %</td>
								<td>{{printDecimal(partnerstatistics.booking_count !== 0 && partnerstatistics.visits !== 0 ? partnerstatistics.booking_count / partnerstatistics.visits * 100 : 0)}} %</td>
								<td>{{partnerstatistics.personen}}</td>
								<td>{{partnerstatistics.bookinggesamt_sum.toLocaleString(undefined,{style: 'currency', currency: 'EUR'})}}</td>
								<td>{{printDecimal(partnerstatistics.bookinggesamt_sum !== 0 && partnerstatistics.personen !== 0 ? partnerstatistics.bookinggesamt_sum / partnerstatistics.personen : 0)}} €</td>
								<td></td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
	import {saveAs} from 'file-saver';
	import writeXlsxFile from 'write-excel-file';
	import Layout from "../layouts/Layout.vue";
	import SaveSpinner from "../views/Spinner.vue";
	import Error from "../views/Error.vue";

	import Vuex from "vuex";
	//get an alias to mapState. I map the properties in the store to our computed aliases.
	const ms = Vuex.mapState;
	import cloneDeep from "lodash.clonedeep";

	//abstract page
	import Page from "../pages/Page.vue";

	export default {
		data() {
			return {
				partnerstatistics: cloneDeep(this.$store.state.partnerstatistics)
			};
		},
		computed: {
			...ms(["ui"])
		},
		components: { Error, Layout, SaveSpinner },
		mixins: [Page],
		methods: {
			listPartnerStatistics: async function() {
				this.$store.commit("setui", { loadingflag: true });
				//until performance is fixed ( or beyond?), only do an http request of the data is not already in our local storage
				if (
					Object.keys(this.$store.state.partnerstatistics).length === 0 ||
					typeof this.$store.state.partnerstatistics !== "object"
				) {
					try {
						await this.$store.dispatch("getpartnerstatistics", {});
						this.partnerstatistics = cloneDeep(this.$store.state.partnerstatistics);
					} catch {
						this.$store.commit("setui", {  errorflag: true });
					}
				}
				this.partnerstatistics.jahre.forEach(j => j.statistics.sort((a,b) => b.monat - a.monat));
				this.$store.commit("setui", { loadingflag: false });
			},
			calculateMonthDiff: function(umbook, yearIndex, month) {
				let prevUmbook = this.partnerstatistics.jahre[yearIndex+1]?.statistics.find(m => m.monat == month)?.bookinggesamt_sum || 0

				if(prevUmbook == 0 && umbook > 0){
					return umbook.toFixed(2).toString();
				}else if(prevUmbook == 0 && umbook == 0){
					return prevUmbook.toFixed(2).toString();
				}

				return ((umbook - prevUmbook) / prevUmbook * 100).toFixed(2).toString();
			},
			calculateYearDiff: function(umbook, yearIndex){
				let prevUmbook = this.partnerstatistics.jahre[yearIndex + 1]?.bookinggesamt_sum || 0;

				if(prevUmbook == 0 && umbook > 0){
					return umbook.toFixed(2).toString();
				}else if(prevUmbook == 0 && umbook == 0){
					return prevUmbook.toFixed(2).toString();
				}

				return ((umbook - prevUmbook) / prevUmbook * 100).toFixed(2).toString();
			},
			printDecimal: function(integer, place=2){
				return integer.toLocaleString(undefined,{minimumFractionDigits: place, maximumFractionDigits: place});
			},
			calculateMonthDiffForExport: function(umbook, year, month){
				let prevUmbook = this.partnerstatistics.jahre.find(j => j.jahr == year - 1)?.statistics.find(m => m.monat == month)?.bookinggesamt_sum || 0

				if(prevUmbook == 0 && umbook > 0){
					return umbook.toFixed(2).toString();
				}else if(prevUmbook == 0 && umbook == 0){
					return prevUmbook.toFixed(2).toString();
				}

				return ((umbook - prevUmbook) / prevUmbook * 100).toFixed(2).toString();
			},
			formatDate: function (date, mask = "yyyy-mm-dd"){
				const month = date.getMonth() + 1;
				const day = date.getDate();

				mask = mask.replace(/yyyy/, date.getFullYear().toString());
				mask = mask.replace(/mm/, month > 9 ? month.toString() : "0" + month);
				mask = mask.replace(/dd/, day > 9 ? date.getDate().toString() : "0" + day);

				return mask;
			},
			exportReport: async function(){
				const schema = [
					{column: this.$t('year'), type: Number, value: item => item.jahr, width: 8, align: "right"},
					{column: this.$t('month'), type: Number, value: item => item.monat, width: 7, align: "right"},
					{column: "Visits", type: String, value: item => item.visits.toLocaleString(), width: 13, align: "right"},
					{column: "BUA", type: String, value: item => item.bookingrequest_count.toLocaleString(), width: 10, align: "right"},
					{column: "BU", type: String, value: item => item.booking_count.toLocaleString(), width: 10, align: "right"},
					{column: "stBu", type: String, value: item => item.stornos.toLocaleString(), width: 10, align: "right"},
					{column: "BUA / Visits", type: String, value: item => this.printDecimal(item.bookingrequest_count !== 0 && item.visits !== 0 ? item.bookingrequest_count / item.visits * 100 : 0) + " %", width: 11, align: "right"},
					{column: "BU / Visits", type: String, value: item => this.printDecimal(item.booking_count !== 0 && item.visits !== 0 ? item.booking_count / item.visits * 100 : 0) + " %", width: 11, align: "right"},
					{column: "PAX", type: Number, value: item => item.personen, width: 10, align: "right"},
					{column: "UmsatzGes", type: String, value: item => item.bookinggesamt_sum.toLocaleString(undefined,{style: 'currency', currency: 'EUR'}), width: 15, align: "right"},
					{column: "UmsatzPAX", type: String, value: item => this.printDecimal(item.bookinggesamt_sum !== 0 && item.personen !== 0 ? item.bookinggesamt_sum / item.personen : 0) + " €", width: 14, align: "right"},
					{column: this.$t('report_change'), type: String, value: item => this.calculateMonthDiffForExport(item.bookinggesamt_sum, item.jahr, item.monat) + " %", width: 17, align: "right"}
				];
				let filenamePrefix = "ehoi"
				switch(this.$store.state.auth.mandant){
					case 104:
					filenamePrefix = "cruisewinkel"
					break;
					case 105:
					filenamePrefix = "wondercruises"
					break;
					case 106:
					filenamePrefix = "zeetours"
					break;
					case 107:
					filenamePrefix = "cruisetravel"
					break;
					case 109:
					filenamePrefix = "ummundo"
					break;
					case 111:
					filenamePrefix = "ummundo"
					break;
					case 112:
					filenamePrefix = "wondercruises"
					break;
				}
				let filename = `${filenamePrefix}-partnerreport-${this.formatDate(new Date())}.xlsx`;
				let alteredStatistics = this.partnerstatistics.jahre.flatMap(j => j.statistics);
				// andere mandanten reinbringen
				let fileToExport = await writeXlsxFile(alteredStatistics, {schema});
				saveAs(fileToExport, filename);
			}
		},

		//I am executed everytime this component is intialized.
		created() {
			this.listPartnerStatistics(); //loads the partner data
		}
	};
</script>
<style>
	table tr.jahresSumme{
		background-color: var(--bs-primary);
		color: #fff;
	}

	tfoot{
		border-top: solid 3px #fff;
		background-color: var(--bs-primary);
		color: #fff;
	}

	table thead{
		position: sticky;
		top: 0;
	}

	.legende{
		display: inline-block;
		color: white;
		background-color: var(--bs-primary);
		border-top-left-radius: 30px;
		border-bottom-right-radius: 30px;
		margin: .5rem 0;
		padding: 1rem;
	}
	.white-space-nowrap{
		white-space: nowrap;
	}

	.fixed-tableheight{
		height: 700px;
	}
</style>
