<template>
	<Layout>
		<div>
			<SaveSpinner  :loadFlag="ui.loadingflag" />
			
			<!-- Modal for user delete-->
			<div class="modal fade" id="confirmDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmDeleteModalTitle" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title" id="confirmDeleteModalTitle">{{$t('delete_user')}}?</h5>
					<button type="button" class="close" data-dismiss="modal" :aria-label="$t('cancel')">
					<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-secondary" data-dismiss="modal">{{$t('cancel')}}</button>
					<button type="button" class="btn btn-primary" v-on:click="deletePartnerUser">{{$t('delete')}}</button>
				</div>
				</div>
			</div>
			</div>

			<div class="content">
				<div v-if="error">
					<div class="alert alert-danger">
						<Error />
					</div>
				</div>
				<div v-else-if="loadingflag === false">
					<div v-if="selectedUser == null">
						<div class="d-flex">
							<div class="mr-3 mb-1 ml-auto">
								<!-- <router-link tag="button" class="mx-auto btn btn-info" id="button" :to="`/partnerdata/users/edit/newuser`">
									<i class="fa fa-user-plus"></i>
								</router-link> -->
								<button type="button" class="mx-auto btn btn-info" @click="addPartnerUser()">
									<i class="fa fa-user-plus"></i>
								</button>
							</div>
						</div>
						<table class="table table-md table-bordered table-hover">
							<thead>
								<tr>
									<th scope="col">
										<i class="fa fa-cog" aria-hidden="true"></i>
									</th>
									<th scope="col">{{$t('username')}}</th>
									<th scope="col">{{$t('fname')}}</th>
									<th scope="col">{{$t('lname')}}</th>
									<th scope="col">{{$t('department')}}</th>
									<th scope="col">{{$t('phonenumber')}}</th>
									<th scope="col">{{$t('locked')}}</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(user) in existingUsers" :key="user.username">
									<td>
										<i class="todo fa fa-trash" aria-hidden="true" data-toggle="modal" data-target="#confirmDeleteModal" @click="()=>{user_delete=user.username }"></i>
										<i class="edit todo fa fa-pencil-square" aria-hidden="true" role="button" @click="() => {selectedUser = user}"></i>
									</td>
									<td>{{user.username}}</td>
									<td>{{user.firstname}}</td>
									<td>{{user.lastname}}</td>
									<td>{{user.department}}</td>
									<td>{{user.phone}}</td>
									<td v-if="user.accountlocked == 1">{{$t("yes")}}</td>
									<td v-if="user.accountlocked == 0">{{$t("no")}}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div v-if="selectedUser != null">
						<div class="d-flex">
							<div class="mr-3 mb-1 ml-auto">
								<button type="button" class="mx-auto btn btn-info" @click="() => {selectedUser = null}">
									<i class="fa fa-arrow-left"></i> {{$t("user_overview")}}
								</button>
							</div>
						</div>
						<UserEdit :partneruser="selectedUser" @save="(ev) => {selectedUser.userno = ev}"></UserEdit>
					</div>
				</div>
			</div>
			<Feedback :successMessage="$t('user_saved')" />
		</div>

	</Layout>
</template>

<script>
	import Layout from "../layouts/Layout.vue";
	import SaveSpinner from "../views/Spinner.vue";
	import Feedback from "../views/Feedback.vue";
	import Error from "../views/Error.vue";
	import cloneDeep from "lodash.clonedeep";
	import Vuex from "vuex";
	import $ from "jquery";
	import UserEdit from "../views/UserEdit.vue";

	//validators
	//import { required, minLength, alpha, numeric } from 'vuelidate/lib/validators'
	//html elements
	//import VText from '../views/htmlelements/VText.vue'
	//import VCheckbox from '../views/htmlelements/VCheckbox.vue'
	//import VSelect from '../views/htmlelements/VSelect.vue'
	//import FEC from '../views/htmlelements/FormElementContainer.vue'
	//abstract page
	import Page from "../pages/Page.vue";
	//import { mapMutations } from 'vuex'
	//get an alias to mapState. I map the properties in the store to our computed aliases.
	const ms = Vuex.mapState;

	export default {
		data() {
			return {
				partnerusers: cloneDeep(this.$store.state.partnerusers),
				user_delete: '',
				selectedUser: null
			};
		},
		components: { Layout, SaveSpinner, Feedback, Error, UserEdit },
		mixins: [Page],
		methods: {
			listPartnerUsers: async function() {
				this.$store.commit("setui", { loadingflag: true })
				try {
					await this.$store.dispatch("listpartnerusers", {})
				} catch {
					this.$store.commit("setui", { loadingflag: false, errorflag: true })
				}
				this.partnerusers = cloneDeep(this.$store.state.partnerusers)
				this.$store.commit("setui", { loadingflag: false })
			},
			addPartnerUser: function(){
				this.partnerusers.push(
					cloneDeep(this.partnerusers.find(p => p.username == ''))
				);
				this.selectedUser = this.partnerusers.find(p => p.username == '')
			},
			deletePartnerUser: async function() {
				$('#confirmDeleteModal').modal('hide')
				this.$store.commit("setui", { loadingflag: true })
				try {
					await this.$store.dispatch("deletepartneruser", {username:this.user_delete})
				} catch {
					this.$store.commit("setui", { loadingflag: false, errorflag: true })
				}
				this.$store.commit("setui", { loadingflag: false })
				this.listPartnerUsers()
			}
		}, //end methods
		computed: {
			existingUsers: function() {
				return this.partnerusers.filter(function(pu) {
					return pu.username.length > 0; //our array also includes an empty userbean which we use to bind to new partnerusers. Do not show it here.
				});
			},
			...ms(["ui"])
		},
		//I am executed everytime this component is intialized.
		created() {
			this.listPartnerUsers();
		}
	};
</script>
<style>
	@media (min-width: 786px) {
		.edit {
			color: var(--bs-primary);
			margin-left: 5px;
		}
	}
	@media (max-width: 758px) {
		table {
			overflow-x: scroll;
			display: block;
		}
	}
	.todo {
		color: var(--bs-primary);
		cursor: pointer;
	}

</style>
