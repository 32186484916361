<template>
	<Layout v-bind:subhead="false">
		<div id="formContent" class="mx-auto">
			<SaveSpinner  :loadFlag="ui.loadingflag" />
			<template v-if="form == 'partner'">
				<form @submit.prevent="checkPartner">
					<div class="container px-2">
						<div>
							<img src="@/assets/images/user-icon.svg" class="col-sm-2 mx-auto" id="icon" alt="User Icon" v-if="$store.getters.getAppMandant == 101" />
							<img src="@/assets/images/user-icon_cw.svg" class="col-sm-2 mx-auto" id="icon" alt="User Icon" v-else-if="$store.getters.getAppMandant == 104" />
							<img src="@/assets/images/user-icon_wc.svg" class="col-sm-2 mx-auto" id="icon" alt="User Icon" v-else-if="$store.getters.getAppMandant == 105" />
							<img src="@/assets/images/user-icon_zt.svg" class="col-sm-2 mx-auto" id="icon" alt="User Icon" v-else-if="$store.getters.getAppMandant == 106" />
							<img src="@/assets/images/user-icon_ct.svg" class="col-sm-2 mx-auto" id="icon" alt="User Icon" v-else-if="$store.getters.getAppMandant == 107" />
							<img src="@/assets/images/user-icon_um.svg" class="col-sm-2 mx-auto" id="icon" alt="User Icon" v-else-if="$store.getters.getAppMandant == 109" />
							<img src="@/assets/images/user-icon_um.svg" class="col-sm-2 mx-auto" id="icon" alt="User Icon" v-else-if="$store.getters.getAppMandant == 111" />
							<img src="@/assets/images/user-icon_wc.svg" class="col-sm-2 mx-auto" id="icon" alt="User Icon" v-else-if="$store.getters.getAppMandant == 112" />
						</div>
						<div>
							<input type="text" v-model="username" class="col-sm-6 mx-auto form-control m-2 p-2" name="username" :placeholder="$t('partnerId')" />
						</div>
						<div class="spinner-border spinner-sm mx-auto d-block" role="status" v-if="pending">
							<span class="sr-only">Loading...</span>
						</div>
						<div class="alert alert-danger" v-if="invalid >= 400">{{$t('partnernotfound')}}</div>
						<div v-if="!pending">
							<button type="submit" class="btn btn-info m-2">{{$t("checkpartnerid")}}</button>
						</div>
					</div>
				</form>
			</template>
			<template v-if="form == 'login'">
				<form @submit.prevent="login">
					<div class="container px-2">
						<div>
							<img src="@/assets/images/user-icon.svg" class="col-sm-2 mx-auto" id="icon" alt="User Icon" v-if="$store.getters.getAppMandant == 101" />
							<img src="@/assets/images/user-icon_cw.svg" class="col-sm-2 mx-auto" id="icon" alt="User Icon" v-else-if="$store.getters.getAppMandant == 104" />
							<img src="@/assets/images/user-icon_wc.svg" class="col-sm-2 mx-auto" id="icon" alt="User Icon" v-else-if="$store.getters.getAppMandant == 105" />
							<img src="@/assets/images/user-icon_zt.svg" class="col-sm-2 mx-auto" id="icon" alt="User Icon" v-else-if="$store.getters.getAppMandant == 106" />
							<img src="@/assets/images/user-icon_ct.svg" class="col-sm-2 mx-auto" id="icon" alt="User Icon" v-else-if="$store.getters.getAppMandant == 107" />
							<img src="@/assets/images/user-icon_um.svg" class="col-sm-2 mx-auto" id="icon" alt="User Icon" v-else-if="$store.getters.getAppMandant == 109" />
							<img src="@/assets/images/user-icon_um.svg" class="col-sm-2 mx-auto" id="icon" alt="User Icon" v-else-if="$store.getters.getAppMandant == 111" />
							<img src="@/assets/images/user-icon_wc.svg" class="col-sm-2 mx-auto" id="icon" alt="User Icon" v-else-if="$store.getters.getAppMandant == 112" />
						</div>
						<div>
							<input type="text" :value="username" class="col-sm-6 mx-auto form-control m-2 p-2" name="username" disabled :placeholder="$t('partnerId')" />
							<input type="password" v-model="password" class="col-sm-6 mx-auto form-control m-2 p-2" name="password" :placeholder="$t('password')" />
						</div>
						<div class="spinner-border spinner-sm mx-auto d-block" role="status" v-if="pending">
							<span class="sr-only">Loading...</span>
						</div>
						<div v-if="!pending">
							<button type="submit" class="btn btn-info m-2">{{$t("login")}}</button>
							<a :href="getWLVpath('wlv/')" target="_blank" class="btn btn-outline-info" v-if="loginSelection.iscbe">
								CBE-Login <i style="color: var(--bs-primary)" class="fa fa-external-link"></i>
							</a>
						</div>
						<div v-if="loginSelection.iscbe">
							<p class="alert alert-info">{{$t("cbe_login_info")}}</p>
						</div>
					</div>
				</form>
				<div class="alert alert-danger" v-if="invalid >= 400">{{$t('invalidlogin')}}</div>
				<div class="alert alert-success" v-if="emailSent">{{$t('newPasswordSent')}}</div>
				<div class="alert alert-danger" v-if="errorOccured">{{errorText}}</div>
				<div id="formFooter">
					<div class="underlineHover d-inline mr-2" role="button" @click="form = 'partner'">{{$t('back')}}</div>
					<button type="button" class="mx-auto btn btn-info" @click="resetPassword" v-if="parseInt(username) > 0">{{$t('request_password')}}</button>
				</div>
			</template>
		</div>
	</Layout>
</template>


<script>
import Layout from "../layouts/Layout.vue";
import SaveSpinner from "../views/Spinner.vue";
//abstract page
import Page from "../pages/Page.vue";
import Vuex from "vuex";
//get an alias to mapState. I map the properties in the store to our computed aliases.
const ms = Vuex.mapState;

import {getWLVDomain} from "../lib/mandant.js";

export default {
	data() {
		return {
			username: "",
			password: "",
			invalid: 0,
			form: "partner",
			pending: false,
			errorOccured: false,
			emailSent: false,
			errorText: "",
			loginSelection: {
				iscbe: false,
				partnerid: 0,
				username: ""
			}
		};
	},
	props: {
		logout: { default: false, type: Boolean }
	},
	components: { Layout, SaveSpinner },
	computed: {
			...ms(["ui"])
	},
	mixins: [Page],
	methods: {
		getWLVpath: function(url){
			let urlparams = `?partnerid=${this.loginSelection.partnerid}&fuseaction=cbe_login.index&username=${this.loginSelection.username}`;

			return getWLVDomain(this.loginSelection.mandant) + url + urlparams
		},
		login: async function() {
			this.invalid = 0;
			let username = this.username;
			let password = this.password;
			this.$store.commit("setui", { loadingflag: true })
			try {
				await this.$store.dispatch("login", { username, password });
				this.$store.commit("setui", { loadingflag: false })
				this.$router.push("/");
			} catch(err) { //failed logins should not be reported as errors. Thus we try/catch them
				this.$store.commit("setui", { loadingflag: false })
				this.invalid = 401 //failed login attempt.
			}
		},
		checkPartner: async function(){
			this.invalid = 0;
			this.pending = true;
			let username = this.username;
			await this.$store.dispatch("checkpartner", username)
			.then((resp) => {
				this.loginSelection = resp;
				this.form = "login"
				this.pending = false;
			})
			.catch(() => {
				this.invalid = 401 //failed login attempt.
			})
			.finally(() => {
				this.pending = false;
			});
		},
		resetPassword: async function() {
			this.invalid = 0;
			this.errorOccured = false
			this.emailSent = false
			this.$store.commit("setui", { loadingflag: true })
			try {
				await this.$store.dispatch("passwordreset", { partnerId: parseInt(this.username) });
				this.$store.commit("setui", { loadingflag: false })
				this.emailSent = true
			} catch(err) {
				this.$store.commit("setui", { loadingflag: false })
				this.errorOccured = true
				if(err.errText.response.status == 400){
					this.errorText = this.$t('partnernotfound');
				}else{
					this.errorText = this.$t('newPasswordNotSent');
				}
			}
		}
	},
	mounted() {
		if (this.logout === true) {
			this.$store.dispatch("logout").then(() => {
				this.$router.push("/login");
			});
		}
	}
};
</script>
<style>
@media (max-width: 991px) {
	input {
		margin: 8px 8px;
	}
	.wrapper {
		padding-top: 8rem;
	}
}
.wrapper {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	min-height: 100%;
	padding: 80px;
}

#formContent {
	border-radius: 10px 10px 10px 10px;
	background: #fff;
	width: 100%;
	max-width: 650px;
	box-shadow: 0 30px 60px 0 rgba(0, 0, 0, 0.3);
	text-align: center;
}

#formFooter {
	background-color: #f6f6f6;
	border-top: 1px solid #dce8f1;
	padding: 12px;
	text-align: center;
	border-radius: 0 0 10px 10px;
}

button {
	background-color: var(--bs-primary);
}

.underlineHover {
	color: var(--bs-primary);
}

.underlineHover:hover {
	text-decoration: underline;
}
#icon {
	width: 20%;
	margin-top: 10px;
}
.spinner-sm{
	width: 3rem !important;
	height: 3rem !important;
	margin-top: 0 !important;
}

.btn-outline-info:hover{
	color: var(--bs-primary) !important;
}
</style>
