<template>
					<table class="mx-auto">
						<thead>
							<tr>
								<th>Date</th>
								<th>Visits</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="hit in hits" :key="hit.date">
								<td>{{hit.date}}</td>
								<td>{{hit.visits.toLocaleString()}}</td>
							</tr>
						</tbody>
					</table>
</template>

<script>
export default {
        props:{
            hits:{
                type:Array,
                required:true
            }
        },

		methods: {
		}

}
</script>	